$font-color: #000;
$red: #6D0F05;
$gold: #9F874B;
$brown: #543113;
$white: #F6F3EB;
$insta: #6b8281;
$highlight: #3d6363;
$background: #F7F7F7;
$blue: #6babaa;
$purple: #6b6cab;
$gray: #e4eaeb;
$myfriendwill: #C1C2C0;

$main-font-size: 18px;
$main-font-height: 140%;

$breakpoint-mobile: 600px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 980px;
$breakpoint-max: 1230px;
$breakpoint-wide: 1280px;

@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

 @font-face {
	font-family: 'museosans';
	src: url('../fonts/MuseoSans/ms_100.eot');
	src: url('../fonts/MuseoSans/ms_100.eot?#iefix') format('embedded-opentype'),
	     url('../fonts/MuseoSans/ms_100.woff') format('woff'),
	     url('../fonts/MuseoSans/ms_100.ttf') format('truetype');
	font-weight: 100;
	font-style: normal;
}

 @font-face {
	font-family: 'museosans';
	src: url('../fonts/MuseoSans/ms_100_italic.eot');
	src: url('../fonts/MuseoSans/ms_100_italic.eot?#iefix') format('embedded-opentype'),
	     url('../fonts/MuseoSans/ms_100_italic.woff') format('woff'),
	     url('../fonts/MuseoSans/ms_100_italic.ttf') format('truetype');
	font-weight: 100;
	font-style: italic;
}

 @font-face {
	font-family: 'museosans';
	src: url('../fonts/MuseoSans/ms_300.eot');
	src: url('../fonts/MuseoSans/ms_300.eot?#iefix') format('embedded-opentype'),
	     url('../fonts/MuseoSans/ms_300.woff') format('woff'),
	     url('../fonts/MuseoSans/ms_300.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

 @font-face {
	font-family: 'museosans';
	src: url('../fonts/MuseoSans/ms_300_italic.eot');
	src: url('../fonts/MuseoSans/ms_300_italic.eot?#iefix') format('embedded-opentype'),
	     url('../fonts/MuseoSans/ms_300_italic.woff') format('woff'),
	     url('../fonts/MuseoSans/ms_300_italic.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
}

 @font-face {
	font-family: 'museosans';
	src: url('../fonts/MuseoSans/ms_500.eot');
	src: url('../fonts/MuseoSans/ms_500.eot?#iefix') format('embedded-opentype'),
	     url('../fonts/MuseoSans/ms_500.woff') format('woff'),
	     url('../fonts/MuseoSans/ms_500.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

 @font-face {
	font-family: 'museosans';
	src: url('../fonts/MuseoSans/ms_500_italic.eot');
	src: url('../fonts/MuseoSans/ms_500_italic.eot?#iefix') format('embedded-opentype'),
	     url('../fonts/MuseoSans/ms_500_italic.woff') format('woff'),
	     url('../fonts/MuseoSans/ms_500_italic.ttf') format('truetype');
	font-weight: 500;
	font-style: italic;
}

 @font-face {
	font-family: 'museosans';
	src: url('../fonts/MuseoSans/ms_700.eot');
	src: url('../fonts/MuseoSans/ms_700.eot?#iefix') format('embedded-opentype'),
	     url('../fonts/MuseoSans/ms_700.woff') format('woff'),
	     url('../fonts/MuseoSans/ms_700.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}

 @font-face {
	font-family: 'museosans';
	src: url('../fonts/MuseoSans/ms_700_italic.eot');
	src: url('../fonts/MuseoSans/ms_700_italic.eot?#iefix') format('embedded-opentype'),
	     url('../fonts/MuseoSans/ms_700_italic.woff') format('woff'),
	     url('../fonts/MuseoSans/ms_700_italic.ttf') format('truetype');
	font-weight: 700;
	font-style: italic;
}

 @font-face {
	font-family: 'museosans';
	src: url('../fonts/MuseoSans/ms_900.eot');
	src: url('../fonts/MuseoSans/ms_900.eot?#iefix') format('embedded-opentype'),
	     url('../fonts/MuseoSans/ms_900.woff') format('woff'),
	     url('../fonts/MuseoSans/ms_900.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}

 @font-face {
	font-family: 'museosans';
	src: url('../fonts/MuseoSans/ms_900_italic.eot');
	src: url('../fonts/MuseoSans/ms_900_italic.eot?#iefix') format('embedded-opentype'),
	     url('../fonts/MuseoSans/ms_900_italic.woff') format('woff'),
	     url('../fonts/MuseoSans/ms_900_italic.ttf') format('truetype');
	font-weight: 900;
	font-style: italic;
}
 
 @font-face {
	font-family: 'albayan';
	src: url('../fonts/AlBayan/AlBayan.eot');
	src: url('../fonts/AlBayan/AlBayan.eot?#iefix') format('embedded-opentype'),
	     url('../fonts/AlBayan/AlBayan.woff') format('woff'),
	     url('../fonts/AlBayan/AlBayan.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}