@import 'base';

body {
	color: #000;
	background: url(../images/vintage.png);
	background-repeat: repeat;
	margin: 0;
	padding: 0;
	font-family: albayan, 'Arial', sans-serif;
	font-weight: 300;
	font-size: $main-font-size;
	line-height: $main-font-height;
}

h2, h1, h3 {
  font-weight: 100;
  font-size: 30px;
  font-weight: bold;
  margin: 10px 0;
  line-height: 120%;
  .letter {
  	font-size: 120%;
  }
}

h1.title {
	color: $red;
	margin: 20px 0;
	font-size: 35px;
	font-family: albayan, 'Arial', sans-serif;
	font-weight: bold;
	text-transform: uppercase;
	-webkit-text-stroke-width: 3px;
    -webkit-text-stroke-color: $font-color;  
    @media(min-width: $breakpoint-tablet) {
		font-size: 45px;
		-webkit-text-stroke-width: 4px;
	}  
}
.thank-you {
  font-weight: 100;
  font-size: 20px;
  font-weight: bold;
  margin: 10px 0;
  color: #000;
  line-height: 120%;
}

h3 {
	margin-top: 30px;
	&::before {
	    display: block;
	    content: "";
	    border-top: .1rem solid #000;
	    width: 6rem;
	    margin: 35px 0 0;
	    transform: translateY(-1rem);
	}
}
div {
	box-sizing: border-box;
}
p {
	font-size: $main-font-size;
	margin: 15px 0;
	text-align: justify;
}
a {
	text-decoration: none;
	color: $blue;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}
.inline-image {
	text-align: center;
	@media(min-width: $breakpoint-tablet) {
		text-align: left;
	}
}
img {
	width: 200px;
	padding-top: 40px;
}
.button {
	position: relative;
	display: inline-block;
	background: $blue;
	padding: 0 50px 0 15px;
	margin: 20px 0;
	border-radius: 5px 25px 25px 5px;
	white-space: nowrap;
	text-decoration: none;
	line-height: 45px;
	font-weight: 100;
	box-shadow: 1px 1px 5px $highlight;
	color: $background;
	border: 1px solid $blue;
	transition-duration: .3s;
	&:hover {
		box-shadow: 3px 3px 7px $highlight;
		border: 1px solid $background;
	}
	svg {
		fill: $background;
		position: absolute;
		top: 10px;
		right: 15px;
	}
}
.strong {
	font-weight: 500;
	color: $red;
}
.larger {
	font-size: 22px;
}
.header-space {
	width: 100%;
	height: 80px;
}
section {
	position: relative;
	.content {
		max-width: 1000px;
		margin: 0 auto;
		overflow-y: hidden;
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: center;
		.section-info {
			padding: 40px 20px;
			@media(min-width: $breakpoint-max) {
				padding: 30px 0;
			}
		}
	}
	&.nav {
		height: 80px;
		border-bottom: 1px solid $red;
		position: fixed;
		width: 100%;
		z-index: 300;
		-webkit-backdrop-filter: blur(5px);
		backdrop-filter: blur(5px);
		padding: 0 20px;
		box-sizing: border-box;
		@supports (-webkit-backdrop-filter: blur(5px)) or (backdrop-filter: blur(5px)) {
			background: rgba(247,247,247,.2);			
		}
		@media(min-width: $breakpoint-max) {
			padding: 0;
		}

		&::after {
			clear: both;
		}

		.content {
			display: block;
			z-index: 150;
		}
	}
	&.hero {
		background: rgba(114, 208, 234,.2);
		.section-info {
			padding-top: 15px;
			padding-bottom: 0;
			color: $font-color;
			width: 100%;
			min-height: 400px;
			@media(min-width: $breakpoint-tablet) {
				padding: 30px 20px;
				width: 450px;
			}
			@media(min-width: $breakpoint-desktop) {
				width: 520px;
			}
		}
		.image {
			display: block;
			width: 100%;
			height: 275px;
			background: transparent url(../images/people_2.png) scroll no-repeat center bottom;
			background-size: contain;
			@media(min-width: $breakpoint-mobile) {
				height: 380px;
			}
			@media(min-width: $breakpoint-tablet) {
				height: 100%;
				width: calc(100% - 350px);
				position: absolute;
				background: transparent url(../images/people_2.png) scroll no-repeat right bottom;
				background-size: contain;
				bottom: 0;
				right: 0;
			}
		}
	}
	&.form {
		background: rgba(0, 153, 51,.2);
		padding-bottom: 40px;
		.image {
			display: block;
			width: 100%;
			max-width: 500px;
			height: 200px;
			background: transparent url(../images/spider.png) scroll no-repeat center bottom;
			background-size: contain;
			@media(min-width: $breakpoint-desktop) {
				max-width: auto;
				height: 550px;
				position: absolute;
				background: transparent url(../images/spider_long.png) scroll no-repeat right bottom;
				background-size: contain;
				top: 0;
				right: 118px;
			}
		}
		#attendees-form {
			padding: 0;
			label {
				fony-size: 18px;
			}
			.form-control {
				position: relative;
				font-size: 18px;
				display: block;
				width: 100%;
				max-width: 500px;
				color: $white;
				background: transparent;
				margin: 5px 0;
				padding: 0 20px 0 5px;
				height: 40px;
				box-shadow: 0;
				border: 2px solid #543113;
				border-radius: 3px;
				box-sizing: border-box;
				font-family: econ sans light,Helvetica,Arial,Sans-serif;
				margin-bottom: 20px;
				&:focus {
					box-shadow: 0;
					border-color: $white;
				}
			}
			.btn, .btn-primary, .btn-lg {
				background: $red;
				border: 1px solid #000;
				width: 100%;
				height: 40px;
				font-size: 18px;
				color: $white;
				text-transform: uppercase;
				max-width: 500px;
				margin-bottom: 0;
				&:hover {
					background: $brown;
				}
			}
		}
		.section-info {
			padding: 15px 20px 0;
			@media(min-width: $breakpoint-tablet) {
				padding: 30px 20px;
			}
			@media(min-width: $breakpoint-max) {
				padding: 30px 0;
			}
		}
	}
	
	&.contact {
		background: rgba(84, 49, 19,.4);
		padding: 0;
		color: $brown;
		.section-info {
			padding-top: 15px;
			@media(min-width: $breakpoint-tablet) {
				padding-top: 30px;
			}
		}
		.text {
			@media(min-width: $breakpoint-desktop) {
				margin-right: 750px;
			}
		}

	}
}